import { loadDependencies } from "./dependencies";
import { initializeApplication } from "./initialization";

try {
    await loadDependencies();
    await initializeApplication();
} catch (error) {
    console.error(error);

    // Display the error message in the user's language
    // If the user's language is not supported, default to English
    const userLanguage = navigator.language.split("-")[0];
    const errorLanguages = ["de", "en", "es", "fr", "ja", "zh"];
    const targetLanguage = errorLanguages.includes(userLanguage) ? userLanguage : "en";
    const rootErrorContainer = document.getElementById(`root-error-container-${targetLanguage}`);
    if (rootErrorContainer) {
        rootErrorContainer.style.display = "grid";
    }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { datadogRum } from "@datadog/browser-rum";
import { ApplicationConfiguration } from "../application-configuration";

export function setupDataDogRUM(applicationConfig: ApplicationConfiguration) {
    datadogRum.init({
        applicationId: applicationConfig.datadog.applicationId,
        clientToken: applicationConfig.datadog.clientToken,
        enableExperimentalFeatures: ["clickmap"],
        env: applicationConfig.datadog.env,
        service: applicationConfig.datadog.service,
        sessionReplaySampleRate: 100, // if not included, the default is 100
        sessionSampleRate: 100,
        silentMultipleInit: true,
        site: "datadoghq.com",
        trackLongTasks: true,
        trackResources: true,
        trackUserInteractions: true,
        useSecureSessionCookie: true,
        version: "1.0.0",
    });

    // Session replay does have an added cost to our RUM bill - https://www.datadoghq.com/pricing/?product=real-user-monitoring--session-replay#real-user-monitoring--session-replay
    // We should determine if we really are getting value from it after a few weeks/months of usage.
    // To disable session replay - https://docs.datadoghq.com/real_user_monitoring/session_replay/#disable-session-replay
    datadogRum.startSessionReplayRecording();
}

import deepEqual from "deep-equal";
import { useEffect, useState } from "react";
import { NavLayoutProps } from "../types";

let navLayout: NavLayoutProps = {
    variant: "primary",
};

export const setNavLayout = (newProps: NavLayoutProps) => {
    if (!deepEqual(navLayout, newProps, { strict: true })) {
        navLayout = newProps;
        window.dispatchEvent(new CustomEvent("mc:nav-layout-updated"));
    }
};
export const useNavLayout = () => {
    const [navLayoutState, setNavLayoutState] = useState(navLayout);
    useEffect(() => {
        function onNavChanged() {
            setNavLayoutState(navLayout);
        }
        window.addEventListener("mc:nav-layout-updated", onNavChanged);
        return () => window.removeEventListener("mc:nav-layout-updated", onNavChanged);
    }, []);
    return navLayoutState;
};

import { getAuthLocalStorageValue, updateAuthLocalStorage } from "./auth-storage";

function base64UrlEncode(url: string): string {
    let base64EncodedString = "";

    try {
        base64EncodedString = window.btoa(url).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
    } catch (e) {
        console.error("provided URL can't be base64 encoded: ", e);
    }

    return base64EncodedString;
}

const buildCmxDestinationLink = (hash?: string) => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");
    const mcToken = getAuthLocalStorageValue("mcToken");
    const isIssuedByMc = getAuthLocalStorageValue("isIssuedByMc");
    const destinationUrl = `${baseUrl}/Main/login/mxRedirect.cfm?dest=/mx-application${hash ?? ""}`;

    if (!baseUrl) {
        return "";
    }

    if (isIssuedByMc) {
        return `${baseUrl}/login/sso.cfm?dest=${base64UrlEncode(destinationUrl)}&token=${mcToken}`;
    }

    return destinationUrl;
};

const buildUrl = (hash?: string) => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");
    return `${baseUrl}/index.cfm${hash || ""}`;
};

export const buildQxDestinationLink = (destinationUrl: string) => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");
    const mcToken = getAuthLocalStorageValue("mcToken");
    const isIssuedByMc = getAuthLocalStorageValue("isIssuedByMc");

    if (!baseUrl) {
        return "";
    }

    if (isIssuedByMc) {
        return `${baseUrl}/login/sso.cfm?dest=${base64UrlEncode(destinationUrl)}&token=${mcToken}`;
    }

    return destinationUrl;
};

/**
 * Updates localStorage "@mc/auth" key with authentication data
 * @param isIssuedByMc
 * @param mcToken
 * @param pcsSessionId
 */
export const updateLocalStorageWithToken = ({
    isIssuedByMc,
    mcToken,
    pcsSessionId,
}: {
    isIssuedByMc?: boolean;
    mcToken: string;
    pcsSessionId: string;
}) => {
    if (isIssuedByMc !== undefined) {
        updateAuthLocalStorage({ isIssuedByMc, mcToken, pcsSessionId });
    } else {
        updateAuthLocalStorage({ mcToken, pcsSessionId });
    }
};

/**
 * Gets Login page URL
 */
export const getLoginUrl = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/login/index.cfm`;
};

/**
 * Gets Logout page URL
 */
export const getLogoutUrl = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return `${baseUrl}/authentication/logout`;
};

/**
 * Gets Qx landing page URL
 */
export const getMyMasterControlLink = () => buildQxDestinationLink(buildUrl());

/**
 * Gets Qx Users page URL
 */
export const getUserManagementLink = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    if (!baseUrl) {
        return null;
    }

    return buildQxDestinationLink(buildUrl("#/portal/users"));
};

/**
 * Gets Qx Portal page URL
 */
export const getHubsPortalLink = () => buildQxDestinationLink(buildUrl("#/hubs/portal"));

/**
 * Gets Qx Insights page URL
 */
export const getInsightsLink = () => buildQxDestinationLink(buildUrl("#/insights"));

export const getArchivedFormPacketLink = (formId: string) =>
    buildQxDestinationLink(
        buildUrl(`Main/Forms/html_forms/view.cfm?event=showForm&formid=${formId}&userwishestocontinue=false"`),
    );

export const getUnarchivedFormPacketLink = (packetId: string) =>
    buildQxDestinationLink(buildUrl(`#/hubs/my-mastercontrol/my-tasks/packet-id/${packetId}/redirect"`));

export const isQxFlow = () => {
    const baseUrl = getAuthLocalStorageValue("baseUrl");

    return !!baseUrl;
};

/**
 * Gets Cmx landing page URL
 */
export const getCmxHubsLink = () => buildCmxDestinationLink("/hubs/ebr");

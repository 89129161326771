import { ApplicationConfiguration } from "../application-configuration";
import { setupDataDogRUM } from "./data-dog-rum";

declare global {
    interface Window {
        dataLayer: Record<string, unknown>[];
    }
}

// If the config values have "replace-with-real-" in them, we know they haven't
// been replaced with real values (usually during integration test runs).
const hasFakeConfigValues = new RegExp("replace-with-real-");

/**
 * Sets up the client-side observability provider, which is currently DataDog RUM.
 */
export const setupObservabilityProvider = (applicationConfig: ApplicationConfiguration) => {
    if (
        applicationConfig?.datadog?.applicationId &&
        !hasFakeConfigValues.test(applicationConfig.datadog.applicationId)
    ) {
        setupDataDogRUM(applicationConfig);
    }
};

import OktaAuth, { AuthState, toRelativeUrl } from "@okta/okta-auth-js";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { createStore } from "zustand/vanilla";
import { getAuthLocalStorageValue, updateAuthLocalStorage } from "../auth/auth-storage";
import { ApplicationConfiguration } from "../types";
import { DATE_FORMAT_DATE, DateTime, TIME_FORMAT_24HOUR_TZ, TIME_ZONE_NAME_DEFAULT } from "./date-time";

const MOCK_ACCESS_TOKEN_STORAGE_KEY = "@mc/mock/accessToken";

export type MCAuthClaims = JwtPayload & {
    groups?: string[];
    grp?: string[];
    qxDbUsername?: string;
    tenantId?: string;
    tenantName?: string;
    uid?: string;
    username?: string;
};

/**
 * @deprecated Direct access to the auth client should be avoided wherever possible.
 * Accessing it directly creates a tight coupling to Okta, which we're moving away from.
 * Use the applicationSettingsStore to get access to most authentication information instead
 */
export let authClient: OktaAuth | null;

function getRedirectUrl(applicationConfiguration: ApplicationConfiguration) {
    // Use a relative path if a relative path is provided in the config. Use an absolute path if not.
    return globalThis.document.baseURI // This will create a url based on the document's base path if the config url is relative, or it will create an absolute url if the config url is absolute
        ? new URL(applicationConfiguration.authentication.redirectUri, globalThis.document.baseURI).toString()
        : applicationConfiguration.authentication.redirectUri;
}
interface ApplicationSettingsState {
    accessGroups: Map<string, string[]>;
    accessToken: string | undefined;
    claims: MCAuthClaims | null;
    dateTime: DateTime;
    isAuthenticated: boolean;
    uid: string | null;
}
interface ApplicationSettingsActions {
    setAccessToken: (token: string | undefined) => void;
    setApplicationConfiguration: (applicationConfiguration: ApplicationConfiguration) => void;
    setDateTime: (dateTime: DateTime) => void;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const initialState: ApplicationSettingsState = {
    accessGroups: new Map<string, string[]>(),
    accessToken: undefined,
    claims: null,
    dateTime: {
        dateFormat: DATE_FORMAT_DATE,
        timeFormat: TIME_FORMAT_24HOUR_TZ,
        timeZoneAuto: false,
        timeZoneName: TIME_ZONE_NAME_DEFAULT,
        utcOffset: "00:00",
    },
    isAuthenticated: false,
    uid: null,
};

/**
 * Provides access to application setting values.
 * Outside of a React component, you can consume it by using `applicationSettingsStore.getState()` to get a current snapshot or you can use `applicationSettingsStore.subscribe(s => s.someStateValue)`to subscribe to changes in a value.
 * Inside of a React component, you can consume it by using `useStore(applicationSettingsStore)`.
 *
 * Don't add new values to this unless they are absolutely necessary. These values create couplings between applications; if there are too many couplings, refactoring becomes difficult.
 */
export const applicationSettingsStore = createStore<ApplicationSettingsState & ApplicationSettingsActions>((set) => {
    const setAccessToken = (token: string | undefined) => {
        if (token) {
            const decodedToken = jwtDecode<MCAuthClaims>(token);
            set((currentState) => ({
                accessGroups: new Map(
                    currentState.accessGroups.set(token, decodedToken.groups || decodedToken.grp || []),
                ),
                accessToken: token,
                claims: decodedToken,
                uid: decodedToken.uid,
            }));
        } else {
            set({
                accessGroups: new Map<string, string[]>(),
                accessToken: token,
                claims: null,
                uid: null,
            });
        }
    };
    const setIsAuthenticated = (isAuthenticated: boolean) => set({ isAuthenticated });
    return {
        ...initialState,
        setAccessToken,
        setApplicationConfiguration: (applicationConfiguration) => {
            // Unsubscribe from the previous auth client
            authClient?.authStateManager.unsubscribe();
            const searchParams = new URLSearchParams(window.location.search);
            const jwt = searchParams.get("token");
            const mcToken = jwt || getAuthLocalStorageValue("mcToken");

            const shouldBeMocked = !!applicationConfiguration.authentication?.shouldBeMocked;
            if (!shouldBeMocked) {
                if (jwt) {
                    updateAuthLocalStorage({ isIssuedByMc: true, mcToken });
                }

                const redirectUrl = getRedirectUrl(applicationConfiguration);
                authClient = new OktaAuth({
                    ...applicationConfiguration.authentication,
                    redirectUrl,
                    restoreOriginalUri: (_oktaAuth: OktaAuth, originalUri: string) => {
                        const uri = toRelativeUrl(originalUri || "/", window.location.origin);
                        window.location.replace(uri);
                    },
                });

                authClient.authStateManager.subscribe((authState: AuthState) => {
                    if (authState.isAuthenticated !== undefined) {
                        setIsAuthenticated(authState.isAuthenticated);
                    }

                    if (mcToken) {
                        const currentTime = +new Date() / 1000;
                        const decodedToken = jwtDecode<JwtPayload>(mcToken);
                        setAccessToken(mcToken);

                        if ((decodedToken?.exp ?? 0) > currentTime) {
                            setIsAuthenticated(true);
                        }
                    }

                    if (authState.accessToken && !mcToken) {
                        setAccessToken(authState.accessToken.accessToken);
                    }
                });
                authClient.start();
                setAccessToken(undefined);
                setIsAuthenticated(false);
            } else {
                authClient = new OktaAuth({
                    issuer: "https://mclabstest.oktapreview.com/oauth2/default",
                });
                const accessToken = window?.localStorage?.getItem(MOCK_ACCESS_TOKEN_STORAGE_KEY) ?? undefined;
                setAccessToken(accessToken);
                setIsAuthenticated(true);
            }
        },
        setDateTime: (dateTime: DateTime) => set({ dateTime }),
        setIsAuthenticated,
    };
});

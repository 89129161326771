import { JwtPayload, jwtDecode } from "jwt-decode";

export const isAuthFlow = () => {
    const location = window.location;
    const searchParams = new URLSearchParams(location.search);

    const qxTenant = searchParams.get("qxTenant");
    const oktaToken = searchParams.get("oktaToken");

    const jwt = searchParams.get("token");
    const dest = searchParams.get("dest");

    const isOldFlow = !!qxTenant && !!oktaToken;
    const isNewJwtFlow = !!jwt && !!dest;

    return isOldFlow || isNewJwtFlow;
};

export const getTtlFromClaims = (claims: JwtPayload | null) => {
    return claims && claims.exp ? claims.exp - Math.floor(Date.now() / 1000) : undefined;
};

export const getLifetimeFromClaims = (claims: JwtPayload | null) => {
    return claims && claims.exp && claims.iat ? claims.exp - claims.iat : undefined;
};

export const getIdleTimeFromClaims = (claims: (JwtPayload & { idleTime?: number }) | null) => {
    return (claims && claims?.idleTime) || undefined;
};

export const getTtlFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getTtlFromClaims(claims);
};

export const getIdleTimeFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getIdleTimeFromClaims(claims);
};

export const getTokenLifetimeFromToken = (accessToken: string) => {
    const claims = jwtDecode(accessToken);
    return getLifetimeFromClaims(claims);
};

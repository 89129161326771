import { parse } from "yaml";
import { ApplicationConfiguration } from "./application-configuration";

// See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
export function deepFreeze<T extends object>(object: T): T {
    // Retrieve the property names defined on object
    const propNames = Reflect.ownKeys(object);

    // Freeze properties before freezing self
    for (const name of propNames) {
        const value = object[name as keyof T];

        if ((value && typeof value === "object") || typeof value === "function") {
            deepFreeze(value);
        }
    }

    return Object.freeze(object);
}

export async function fetchConfig(): Promise<ApplicationConfiguration> {
    const url = `${window.location.protocol}//${window.location.host}/config/config.yml`;

    return fetch(url)
        .then((response) => response.text())
        .then(parse);
}

import { ApplicationConfiguration } from "./types";

/**
 * The global application configuration object. Used throughout our applications.
 * @note This is set by the `adapt-host` at startup, but begins pre-initialized for unit testing purposes.
 */
export let applicationConfig: ApplicationConfiguration = {
    environment: "production",
};

export const setApplicationConfiguration = (appConfig: ApplicationConfiguration) => (applicationConfig = appConfig);

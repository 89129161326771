import { init as initApplicationSettings } from "@mc/application-settings";
import { registerApplication, start } from "single-spa";
import { constructApplications, constructLayoutEngine, constructRoutes } from "single-spa-layout";
import { initI18n } from "../i18n/i18n";
import { loadApplicationConfiguration } from "./application-configuration/application-configuration";
import { waitForSpaFirstMount } from "./errorHandling";
import { setupObservabilityProvider } from "./observability/observability-provider";

const INITIALIZATION_TIMEOUT = 30000;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appMap: { [key: string]: () => Promise<any> } = {
    "@mc/amx-config-web": () => import("@mc/amx-config-web"),
    "@mc/amx-web": () => import("@mc/amx-web"),
    "@mc/aqem-web": () => import("@mc/aqem-web"),
    "@mc/asset-management-web": () => import("@mc/asset-management-web"),
    "@mc/auth-web": () => import("@mc/auth-web"),
    "@mc/exam-builder-web": () => import("@mc/exam-builder-web"),
    "@mc/homepage-web": () => import("@mc/homepage-web"),
    "@mc/insights-web": () => import("@mc/insights-web"),
    "@mc/integrations-web": () => import("@mc/integrations-web"),
    "@mc/logbooks-web": () => import("@mc/logbooks-web"),
    "@mc/mcadmin-web": () => import("@mc/mcadmin-web"),
    "@mc/navigation-web": () => import("@mc/navigation-web"),
    "@mc/release-center-web": () => import("@mc/release-center-web"),
    "@mc/summarizer-web": () => import("@mc/summarizer-web"),
    "@mc/translator-web": () => import("@mc/translator-web"),
};

/**
 * Initializes the basic required assets for the application.
 */
export async function initializeApplication(): Promise<void | Event | string> {
    return Promise.race([
        // We aren't finished initializing until the first application is mounted
        waitForSpaFirstMount(INITIALIZATION_TIMEOUT),
        (async () => {
            // Before anything else, load application configuration.
            // Application configuration is required for nearly everything else.
            const applicationConfig = await loadApplicationConfiguration();

            // Then, setup our observability provider (which requires application configuration).
            setupObservabilityProvider(applicationConfig);

            const root = document.querySelector("#single-spa-layout") as Element;

            // Construct SPA routes and applications
            const routes = constructRoutes(root);
            const applications = constructApplications({
                loadApp: ({ name }) => appMap[name](),
                routes,
            });

            // Build a layout engine with the constructed routes and applications
            const layoutEngine = constructLayoutEngine({
                active: false,
                applications,
                routes,
            });

            // Initialize application settings and i18n translations
            await initApplicationSettings(applicationConfig, initI18n);

            // Register all applications with single-spa
            for (const app of applications) {
                registerApplication({
                    ...app,
                });
            }

            // Activate the layout engine and startup the app
            layoutEngine.activate();
            start();
        })(),
    ]);
}
